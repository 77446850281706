/* src/styles/Footer.css */

.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem;
    text-align: center;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem;
  }
  
  .footer-social {
    display: flex;
    gap: 1rem;
  }
  
  .footer-social a {
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
  }
  
  .footer-social a:hover {
    color: #f4c542;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
    }
  
    .footer-social {
      margin-top: 1rem;
    }
  }
  