/* src/styles/ContactForm.css */

.contact-form {
    padding: 4rem 2rem;
    background-color: #f4f4f4;
  }
  
  .contact-form h2 {
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .success-message {
    text-align: center;
    font-size: 1.2rem;
    color: green;
    margin-top: 1rem;
  }
  
  form {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #f4c542;
  }
  
  textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: #f4c542;
    color: #333;
    border: none;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #e6b537;
  }
  
  @media (max-width: 768px) {
    form {
      padding: 1.5rem;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 0.9rem;
    }
  }
  