/* src/styles/Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar-logo h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fdfdfd;
  margin: 0;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.navbar-links li {
  position: relative;
  margin: 0;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  display: inline-block;
  padding: 0.2rem 0;
  overflow: hidden;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #f4c542;
}

/* Create before and after pseudo-elements for overline and underline */
.navbar-links a::before,
.navbar-links a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #f4c542;
  transition: transform 0.4s ease;
  transform: scaleX(0); /* Initially hidden */
}

.navbar-links a::before {
  top: 0; /* Overline */
  left: 0;
  transform-origin: left; /* Line will animate from left to right */
}

.navbar-links a::after {
  bottom: 0; /* Underline */
  right: 0;
  transform-origin: right; /* Line will animate from right to left */
}

/* On hover, the lines animate into view */
.navbar-links a:hover::before {
  transform: scaleX(1); /* Animate from left to right */
}

.navbar-links a:hover::after {
  transform: scaleX(1); /* Animate from right to left */
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
    text-align: left;
  }

  .navbar-links li {
    padding: 0.5rem 0;
  }
}
