/* src/styles/About.css */

.about {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f4f4f4, #fff);
  text-align: center;
  color: #333;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.about-image {
  flex: 1;
  padding: 1rem;
  max-width: 400px;
}

.about-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.about-image img:hover {
  transform: scale(1.05);  /* Slight zoom on hover */
}

.about-text {
  flex: 2;
  padding: 1rem;
}

.about-text h2 {
  font-size: 3rem;
  color: #f4c542;
  margin-bottom: 1.5rem;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
}

.about-text p::first-letter {
  font-size: 2rem;
  font-weight: bold;
  color: #f4c542;  /* Accent color for the first letter */
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }

  .about-text h2 {
    font-size: 2.5rem;
  }

  .about-text p {
    font-size: 1.1rem;
  }

  .about-image {
    margin-bottom: 2rem;
  }
}
