/* src/styles/AudioClips.css */

.audio-clips {
  padding: 4rem 2rem;
  background-color: #f4f4f4;
  text-align: center;
}

.audio-clips h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.clips-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-items: center;
}

.clip {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  max-width: 320px;
  width: 100%;
}

.clip:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.clip h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.clip audio {
  width: 100%;
  outline: none;
  margin-top: 1rem;
}

.clip-icon {
  font-size: 3rem;
  color: #f4c542; /* Accent color for the icon */
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .clips-container {
    grid-template-columns: 1fr;
  }

  .clip {
    max-width: 100%;
  }
}
